* {
    margin: 0;
    padding: 0;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centerFlexCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5%;
    align-content: center;
}
@media only screen and (max-width:600px){
    .centerFlex {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin: 5%;
        align-content: space-around;
        overflow-wrap: normal;
    }
}

@media only screen and (min-width:600px) {
    .centerFlex {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: 5%;
        align-content: space-around;
        overflow-wrap: normal;
    }
}

@media only screen and (max-width:600px) {
    .centerFlexWrap {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin: 5%;
        align-content: space-around;
        overflow-wrap: normal;
        flex-wrap: wrap;
    }
}

@media only screen and (min-width:600px) {
    .centerFlexWrap {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: 5%;
        align-content: space-around;
        overflow-wrap: normal;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width:600px) {
    .centerFlexSpaceBetween {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 5%;
        align-content: space-between;
        flex-wrap: wrap;
    }
}

@media only screen and (min-width:600px) {
    .centerFlexSpaceBetween {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        margin: 5%;
        align-content: center;
        flex-wrap: wrap;
    }
}





